<template>
  <div>
    <div class="table_sty">
      <div class="froms">
        <p class="describe">筛选</p>
        <div class="segmentation"></div>
        <div class="from_submit">
          <el-form label-width="60px" :model="formLabelAlign" class="from">
            <el-form-item label="名称：">
              <el-input
                placeholder="请输入名称"
                size="mini"
                class="form-inp"
                v-model="formLabelAlign.name"
              ></el-input>
            </el-form-item>
          </el-form>
          <div class="search_button">
            <div>
              <el-button
                @click="way_tableData(true)"
                type="primary"
                icon="el-icon-search"
                >查询</el-button
              >

              <el-button @click="on_reset" icon="el-icon-refresh-left"
                >重置</el-button
              >
            </div>
          </div>
        </div>
      </div>
      <div class="tab_sty">
        <div class="tab_top">
          <p>
            <span class="span1">一共{{ total }}个</span>
            <span class="span2">已选择 {{ more_List.length }} 个</span>
            <i class="span_i el-icon-refresh" @click="way_tableData()"
              >数据更新</i
            >
          </p>
          <div>
            <el-button
              type="primary"
              @click="on_particulars()"
              icon="el-icon-plus"
              >新建</el-button
            >
            <el-button @click="on_poprw_flow" icon="el-icon-tickets"
              >创建任务</el-button
            >
            <el-button @click="on_deleteList" icon="el-icon-close"
              >删除</el-button
            >
          </div>
        </div>
        <div>
          <el-table
            highlight-current-row
            @current-change="way_singleselect"
            @selection-change="way_moreselect"
            :data="table_List"
            :header-cell-style="{ background: '#f8f8f9' }"
            style="flex: 1"
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column type="index" width="50"> </el-table-column>
            <el-table-column prop="name" label="名称"></el-table-column>
            <el-table-column
              prop="check_times"
              label="检查周期"
            ></el-table-column>
            <el-table-column prop="nickname" label="添加人"></el-table-column>
            <el-table-column prop="add_time" label="添加时间"></el-table-column>
            <el-table-column prop="add_time" label="完成情况">
              <template slot-scope="scope">
                {{ scope.row.finish_task }}/{{ scope.row.task_total }}
              </template>
            </el-table-column>
            <el-table-column prop="score" label="考核分"></el-table-column>

            <el-table-column prop="name" label="操作">
              <template slot-scope="scope">
                <el-link type="primary" @click="on_particulars(scope.row.id)"
                  >修改</el-link
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="paging">
          <el-pagination
            @current-change="way_page"
            :page-size="page_size"
            layout="prev, pager, next, jumper"
            :total="total"
            :current-page="page"
          >
          </el-pagination>
        </div>
      </div>
    </div>

    <el-dialog title="任务" show-close :visible.sync="renwupop_show" center>
      <div>
        <el-form ref="formName" label-width="180px" :model="task_From">
          <div class="time-tys">
            <el-form-item
              label="开始时间："
              prop="begin_time"
              :rules="[{ required: true, message: '请选择开始时间' }]"
            >
              <div>
                <el-date-picker
                  class="time-inp"
                  value-format="yyyy-MM-dd"
                  v-model="task_From.begin_time"
                  type="date"
                  placeholder="开始时间"
                  @change="DateChange()"
                >
                </el-date-picker>
              </div>
            </el-form-item>
            <p>至</p>
            <el-form-item
              label-width="0px"
              label=""
              prop="end_time"
              :rules="[{ required: true, message: '请选择结束时间' }]"
            >
              <div>
                <el-date-picker
                  class="time-inp"
                  value-format="yyyy-MM-dd"
                  v-model="task_From.end_time"
                  type="date"
                  placeholder="结束时间"
                  :picker-options="pickerOptions"
                >
                </el-date-picker>
              </div>
            </el-form-item>
          </div>

          <el-form-item
            label="完成类型："
            prop="finish_type"
            :rules="[{ required: true, message: '请选择完成类型：' }]"
          >
            <el-select
              class="form-inp"
              v-model="task_From.finish_type"
              placeholder="请选择完成类型"
            >
              <el-option
                v-for="item in type_list"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item
            label="任务执行对象："
            prop="checkedCities"
            :rules="[{ required: true, message: '请选择执行人员' }]"
          >
            <el-checkbox-group v-model="task_From.checkedCities">
              <el-checkbox
                v-for="city in cityOptions"
                :label="city.id"
                :key="city.id"
                >{{ city.name }}</el-checkbox
              >
            </el-checkbox-group>
          </el-form-item>
        </el-form>
        <div style="text-align: center; margin: 20px 0">
          <el-button @click="on_preserve('formName')" type="primary">
            保存
          </el-button>
          <el-button @click="renwupop_show = false">返回</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import table_minin from "../../assets/minin/table_minin";
import renwupop from "../../components/renwu_pop";
import {
  evaluationindex,
  evaluationdelete,
  evaluationtasksend,
  WorkInstancesave,
} from "../../assets/request/api";
export default {
  name: "Special_inspection",
  mixins: [table_minin],
  components: {
    renwupop,
  },
  data() {
    return {
      is_extend: true,
      formLabelAlign: {
        name: "",
      },

      //任务
      renwupop_show: false,
      task_From: {
        finish_type: "",
        checkedCities: [],
        task_code: "evaluation_recorder",
        begin_time: "",
        end_time: "",
      },
      type_list: [
        { name: "任一完成", id: 1 },
        { name: "全部完成", id: 2 },
      ],
      cityOptions: [],
    };
  },
  created() {
    this.url = evaluationindex;
    this.delete_Url = evaluationdelete;
    this.fromData = this.formLabelAlign;
    this.xl_attribute = "status_list";
    this.way_tableData();
  },
  methods: {
     way_extend(data) {
       this.cityOptions = data.office_position
    },

    //点击任务
    on_poprw_flow() {
      let { more_List } = this;
      if (more_List.length == 0) {
        this.$message({
          message: "请选择后在选择任务",
          type: "warning",
        });
        return;
      }
      this.task_From.source_ids = more_List.map((item) => item.id).join(",");
      this.renwupop_show = true;
    },

    //统计周期结束日期禁用
    DateChange(type) {
      if (!type) {
        this.task_From.end_time = "";
      }
      let that = this;
      this.pickerOptions = {
        //日期禁用
        disabledDate(date) {
          return that.$tools.Time_todetermine(date, that.task_From.begin_time);
        },
      };
    },

    //点击保存任务
    on_preserve(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let task_From = JSON.parse(JSON.stringify(this.task_From));
          console.log(task_From.checkedCities);
          task_From.office_position = task_From.checkedCities.join(",");

          delete task_From.checkedCities;

          WorkInstancesave(task_From).then((res) => {
            if (res.code == 0) {
              this.$message({
                message: res.msg,
                type: "success",
              });
              let that = this;
              setTimeout(function () {
                that.renwupop_show = false;
              }, 1000);
            }
          });
        } else {
          return false;
        }
      });
    },

    on_particulars(id) {
      this.$router.push({
        path: "/assess/performance_reviewredact",
        query: {
          id: id,
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.describe {
  display: inline-block;
  position: relative;
  padding: 4px;
  font-size: 16px;
  color: #4f7afd;
  font-weight: bold;
  padding-bottom: 10px;
}
.describe:before {
  content: "";
  position: absolute;
  bottom: -2px;
  width: 50%;
  height: 4px;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #4f7afd;
}
.from {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
}
.form-inp {
  width: 200px;
}
.paging {
  text-align: center;
  padding: 10px;
}
.tab_top {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  button {
    margin-right: 10px;
  }
  p {
    display: flex;
    align-items: center;
  }
}
.span1 {
  font-size: 14px;
  color: #000000;
}
.span2 {
  font-size: 14px;
  margin-left: 10px;
  color: #797979;
}
.span_i {
  font-size: 14px;
  color: #797979;
  margin-left: 10px;
  cursor: pointer;
}
.el-divider__text {
  color: #0080ff;
  font-weight: bold;
}
.segmentation {
  height: 1px;
  background-color: #f4f4f4;
  margin-bottom: 20px;
}
.list_sty {
  margin-right: 10px;
}
.search_button {
  display: flex;
  margin-bottom: 18px;
  flex-direction: column-reverse;
}
.form-inp {
  width: 300px;
}
.time-tys {
  display: flex;
  p {
    margin: 0 5px;
    padding-top:7px
  }
  .time-inp {
    width: 160px;
  }
}
</style>
